<template>
  <v-container>
    <v-row align="center">
      <v-col>
        <SearchBox v-model="search" @plusClicked="newClient" />
      </v-col>
      <v-col md="3">
        <v-radio-group v-model="searchActive" row>
          <v-chip class="pl-0 pr-0" color="orange lighten-4" outlined link>
            <v-chip :color="searchActive ? '' : 'deep-orange lighten-2'" class="font-weight-bold text-body-1 white--text"
              link @click="searchActive = false">
              {{ clients.filter((c) => !c.active).length }} inactifs</v-chip>
            <v-switch class="ml-3" inset v-model="searchActive" />

            <v-chip :color="searchActive ? 'deep-orange lighten-2' : ''" class="font-weight-bold text-body-1 white--text"
              @click="searchActive = true">
              {{ clients.filter((c) => c.active).length }} actifs</v-chip>
          </v-chip>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-0 pr-0">

        <v-data-table :headers="[

          { text: 'Prénom', value: 'firstName' },
          { text: 'Nom', value: 'lastName' },
          { text: 'Société', value: 'companyName' },
          { text: 'Abonnements', value: 'memberships' },
          { text: 'Actions', value: 'actions', sortable: false, },
        ]" :items="clients.filter((client) => client.active == searchActive)" :search="search" :loading="loading" :hide-default-footer="true" disable-pagination :sort-by="sortBy"
          :sort-desc="sortDesc" :custom-filter="customFilter">
          <template v-slot:item.firstName="{ item }">
            {{ item.firstName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-1" v-if="item.comment" v-bind="attrs" v-on="on" small>
                  mdi-information
                </v-icon>
              </template>
              <span>{{ item.comment }}</span>
            </v-tooltip>
            <v-chip v-if="item.active == 0" color="red" small text-color="white" class="ml-2">{{
              $t("inactive")
            }}</v-chip>
          </template>
          <template v-slot:item.memberships="{ item }">
            <ClientMembershipsDetails :client="item" @edit="editMembership" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="editClient(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier le client</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="addMembership(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-plus-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Ajouter un abonnement</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.email" :href="`mailto:${item.email}`" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-send
                  </v-icon>
                </v-btn>
              </template>
              <span>Envoyer un e-mail</span>
            </v-tooltip>

          </template>
          <template v-slot:no-data>
            <NoTableData @click="newClient" />
          </template>
          <template v-slot:loading>
            <Loader />
          </template>
        </v-data-table>

        <!-- <v-data-iterator :items="clients" :search="search" :loading="loading" :items-per-page="50"
          :custom-filter="customFilter">
          <template v-slot:loading>
            <Loader />
          </template>
          <template v-slot:no-data>
            <NoTableData @click="newClient" />
          </template>
          <template v-slot:default="props">
            <v-container>
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="4" lg="3">
                  <ClientCard v-bind:client="item" @onEditClicked="editClient" />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-iterator> -->


      </v-col>
    </v-row>

    <ClientEditionPopup ref="clientEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />
    <MembershipEditionPopup ref="membershipEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />
  </v-container>
</template>

<script>
// import ClientCard from "@/components/Admin/Client/ClientCard";
import ClientEditionPopup from "@/components/Admin/Client/ClientEditionPopup";
import ClientMembershipsDetails from "@/components/Admin/Client/ClientMembershipsDetails";
import SearchBox from "@/components/Admin/SearchBox";
import Loader from "@/components/Admin/Loader";
import NoTableData from "@/components/Admin/NoTableData.vue";
import MembershipEditionPopup from "@/components/Admin/Membership/MembershipEditionPopup";

export default {
  components: {
    // ClientCard,
    ClientEditionPopup,
    SearchBox,
    Loader,
    NoTableData,
    ClientMembershipsDetails,
    MembershipEditionPopup,
  },
  data() {
    return {
      loading: false,
      itemsPerPageArray: [4, 8, 12],
      search: "",
      searchActive: true,
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 20,
      sortBy: "firstName",
      keys: [
        "Name",
        "Calories",
        "Fat",
        "Carbs",
        "Protein",
        "Sodium",
        "Calcium",
        "Iron",
      ],
      clients: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.clients.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  methods: {
    getUsedUnits(membership) {
      return membership.planning_memberships.reduce(function (a, b) {
        return Number(a) + Number(b.unit ?? 0);
      }, 0);
    },
    customFilter(items, search, filter) {
      search = search.toString().toLowerCase();

      if (!search) return filter.active === this.searchActive;

      return filter.active === this.searchActive &&
        (filter.firstName?.toLowerCase().includes(search) ||
          filter.lastName?.toLowerCase().includes(search) ||
          filter.email?.toLowerCase().includes(search) ||
          filter.companyName?.toLowerCase().includes(search) ||
          filter.comment?.toLowerCase().includes(search) ||
          filter.groups.find((g) => g.name?.toLowerCase().includes(search)))
    },
    newClient() {
      this.$refs.clientEdition.open({
        active: true,
        language: this.$i18n.locale,
      });
    },
    editClient(client) {
      var value = JSON.parse(JSON.stringify(client));
      this.$refs.clientEdition.open(value);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    saveClicked() {
      this.retrieveAll();
    },
    addMembership(client) {
      this.$refs.membershipEdition.open({
        clientId: client.id,
        active: true,
        vatRate: 21,
        payment: { status: 2 },
      });
    },
    editMembership(membership) {
      var value = JSON.parse(JSON.stringify(membership));
      this.$refs.membershipEdition.open(value);
    },
    retrieveClients() {
      this.loading = true;
      this.$services.client
        .getAll()
        .then((response) => {
          this.clients = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveClients();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>