<template>
    <v-container>
        <v-row>
            <v-col v-if="client.memberships.length > 0">{{ $t("active-memberships", { nb: client.memberships.length }) }}
                <v-btn icon @click="show = !show">
                    <v-icon>{{
                        show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn></v-col>
            <!-- <v-col class="text-align-right"><v-btn @click="addMembership">Ajouter</v-btn></v-col> -->
        </v-row>
        <v-row v-for="membership in client.memberships" :key="membership.id" v-show="show" no-gutters>
            <v-col cols="2">
                <UnitsChip :usedUnits="getUsedUnits(membership)" :totalUnits="Number(membership.membership_type.unit)" />
            </v-col>
            <v-col class="grow">
                {{ $helpers.date.getDateDisplay(membership.dateBegin) }}
                <v-icon small v-if="$helpers.date.getDateDisplay(membership.dateEnd)">mdi-chevron-right</v-icon>
                {{ $helpers.date.getDateDisplay(membership.dateEnd) }}
            </v-col>
            <v-col cols="2">
                {{ membership.priceVatIncluded }} €
            </v-col>

            <v-col cols="2">
                <PaymentStatusChip class="" :payment="membership.payment" />
            </v-col>

            <v-col class="shrink">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="editMembership(membership)" v-bind="attrs" v-on="on" small icon>
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Modifier l'abonnement</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>


    <!-- <v-list>
        <v-list-item v-if="client.memberships.length > 0">
            <v-list-item-content>
                <v-list-item-title>{{ $t("active-memberships", { nb: client.memberships.length }) }}

                    <v-btn icon @click="show = !show">
                        <v-icon>{{
                            show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                    </v-btn>
                </v-list-item-title>
                <v-list-item-subtitle v-for="membership in client.memberships" :key="membership.id" v-show="show">
                    <v-container>
                        <v-row align="center" justify="start" no-gutters>
                            <v-col class="shrink">
                                <UnitsChip :usedUnits="getUsedUnits(membership)"
                                    :totalUnits="Number(membership.membership_type.unit)" class="mr-2" small />
                            </v-col>
                            <v-col class="grow">
                                {{ $helpers.date.getDateDisplay(membership.dateBegin) }}
                                <v-icon small
                                    v-if="$helpers.date.getDateDisplay(membership.dateEnd)">mdi-chevron-right</v-icon>
                                {{ $helpers.date.getDateDisplay(membership.dateEnd) }}
                            </v-col>
                            <v-col class="text-align-right shrink" v-show="membership.priceVatIncluded" cols="2">
                                {{ membership.priceVatIncluded }} €
                            </v-col>

                            <v-col class="ml-4" cols="2">
                                <PaymentStatusChip class="" :payment="membership.payment" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

    </v-list> -->
</template>


<script>
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip";
import UnitsChip from "@/components/Admin/Unit/UnitsChip";
import { VContainer } from "vuetify/lib";

export default {
    props: {
        client: Object,
    },
    components: {
        PaymentStatusChip,
        UnitsChip,
        VContainer
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        getUsedUnits(membership) {
            return membership.planning_memberships.reduce(function (a, b) {
                return Number(a) + Number(b.unit ?? 0);
            }, 0);
        },
        addMembership() {
            this.$emit("add");
        },
        editMembership(membership) {
            this.$emit("edit", membership);
        },
    }
}
</script>