<template>
  <v-chip
    :color="getColor()"
    text-color="white"
    v-bind="$attrs"
    small
  ><span v-if="totalUnitsValue != '~'"
      >{{ usedUnits }}/{{ totalUnitsValue }}</span
    ><v-icon v-else small>mdi-infinity</v-icon></v-chip>
</template>

<script>
export default {
  name: "UnitsChip",
  props: {
    usedUnits: Number,
    totalUnits: Number,
  },
  computed: {
    totalUnitsValue: function () {
      return this.totalUnits == 0 ? "~" : this.totalUnits;
    },
  },
    methods: {
    getColor() {
      if (this.totalUnitsValue == "~") return "orange";

      if (this.usedUnits >= this.totalUnitsValue) return "red";

      return "green";
    },
  },
};
</script>