import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c('SearchBox',{on:{"plusClicked":_vm.newClient},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"md":"3"}},[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.searchActive),callback:function ($$v) {_vm.searchActive=$$v},expression:"searchActive"}},[_c(VChip,{staticClass:"pl-0 pr-0",attrs:{"color":"orange lighten-4","outlined":"","link":""}},[_c(VChip,{staticClass:"font-weight-bold text-body-1 white--text",attrs:{"color":_vm.searchActive ? '' : 'deep-orange lighten-2',"link":""},on:{"click":function($event){_vm.searchActive = false}}},[_vm._v(" "+_vm._s(_vm.clients.filter(function (c) { return !c.active; }).length)+" inactifs")]),_c(VSwitch,{staticClass:"ml-3",attrs:{"inset":""},model:{value:(_vm.searchActive),callback:function ($$v) {_vm.searchActive=$$v},expression:"searchActive"}}),_c(VChip,{staticClass:"font-weight-bold text-body-1 white--text",attrs:{"color":_vm.searchActive ? 'deep-orange lighten-2' : ''},on:{"click":function($event){_vm.searchActive = true}}},[_vm._v(" "+_vm._s(_vm.clients.filter(function (c) { return c.active; }).length)+" actifs")])],1)],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pl-0 pr-0"},[_c(VDataTable,{attrs:{"headers":[

        { text: 'Prénom', value: 'firstName' },
        { text: 'Nom', value: 'lastName' },
        { text: 'Société', value: 'companyName' },
        { text: 'Abonnements', value: 'memberships' },
        { text: 'Actions', value: 'actions', sortable: false, } ],"items":_vm.clients.filter(function (client) { return client.active == _vm.searchActive; }),"search":_vm.search,"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.comment)?_c(VIcon,_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comment))])]),(item.active == 0)?_c(VChip,{staticClass:"ml-2",attrs:{"color":"red","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("inactive")))]):_vm._e()]}},{key:"item.memberships",fn:function(ref){
      var item = ref.item;
return [_c('ClientMembershipsDetails',{attrs:{"client":item},on:{"edit":_vm.editMembership}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editClient(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-pencil-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier le client")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.addMembership(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-plus-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ajouter un abonnement")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.email)?_c(VBtn,_vm._g(_vm._b({attrs:{"href":("mailto:" + (item.email)),"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-send ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Envoyer un e-mail")])])]}},{key:"no-data",fn:function(){return [_c('NoTableData',{on:{"click":_vm.newClient}})]},proxy:true},{key:"loading",fn:function(){return [_c('Loader')]},proxy:true}])})],1)],1),_c('ClientEditionPopup',{ref:"clientEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}}),_c('MembershipEditionPopup',{ref:"membershipEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }